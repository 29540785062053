import React from 'react';

const ThankYou = () => {
    return (
        <div>
            <h3>Thank you! your email was sent </h3>
            <p>We try and respond within 2 business days</p>
        </div>
    )
};

export default ThankYou;