import React from 'react';
import './portfolio.styles.css';
import ImageContainer from '../../components/imageContainer/imageContainer.component';



const Portfolio = () => (
    <div className ='about-us'>
        <ImageContainer />
    </div>
);

export default Portfolio;